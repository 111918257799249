import { SIDE_NAV_LIGHT, NAV_TYPE_SIDE, SIDE_NAV_DARK, DIR_LTR } from 'constants/ThemeConstant';
import { env } from './EnvironmentConfig'

export const APP_NAME = 'Orhan Ergun Business';
export const API_BASE_URL = "http://localhost/api"
export const APP_PREFIX_PATH = '/business';
export const AUTH_PREFIX_PATH = '/auth';
export const AUTH_KEYWORD_NAME = 'keywords';

export const THEME_CONFIG = {
	navCollapsed: false,
	sideNavTheme: SIDE_NAV_DARK,
	locale: 'en',
	navType: NAV_TYPE_SIDE,
	topNavColor: '#3e82f7',
	headerNavColor: '',
	mobileNav: false,
	currentTheme: 'dark',
	direction: DIR_LTR
};
