import {
    DashboardOutlined,
    AppstoreOutlined,
    MailOutlined,
    SettingOutlined,
    EyeOutlined,
    EyeInvisibleOutlined,
    GroupOutlined,
    LeftCircleOutlined,
    PieChartOutlined
} from '@ant-design/icons';
import {APP_PREFIX_PATH} from 'configs/AppConfig'

const backNavTree = [{
    key: 'h',

    path: `${APP_PREFIX_PATH}/h`,
    title: 'Back to OrhanErgun',
    icon: LeftCircleOutlined,
    breadcrumb: false,
    submenu: []
}]
const dashBoardNavTree = [{
    key: 'home',
    path: `${APP_PREFIX_PATH}/home`,
    title: 'home',
    icon: DashboardOutlined,
    breadcrumb: false,
    submenu: []
}]
const appsNavTree = [{
    key: 'apps',
    path: `${APP_PREFIX_PATH}/profile`,
    title: 'Business Manager',
    icon: AppstoreOutlined,
    breadcrumb: false,
    submenu: [
        {
            key: 'apps-mail',
            path: `${APP_PREFIX_PATH}/profile`,
            title: 'Account Details',
            icon: AppstoreOutlined,
            breadcrumb: false,
            submenu: []
        },

    ]
}]

const invitesNavTree = [{
    key: 'invites',
    path: `${APP_PREFIX_PATH}/invites/accepted`,
    title: 'sidenav.invites',
    icon: EyeOutlined,
    breadcrumb: false,
    submenu: [



    ]
}]
const usersNavTree = [{
    key: 'invites',
    path: `${APP_PREFIX_PATH}/invites/accepted`,
    title: ' ',
    icon: EyeOutlined,
    breadcrumb: false,
    submenu: [

        {
            key: 'invites-accepted',
            path: `${APP_PREFIX_PATH}/invites/accepted`,
            title: 'Users',
            icon: EyeOutlined,
            breadcrumb: false,
            submenu: []
        },
        {
            key: 'invites-pending',
            path: `${APP_PREFIX_PATH}/invites/pending`,
            title: 'Pending Invites',
            icon: EyeInvisibleOutlined,
            breadcrumb: false,
            submenu: []
        },

    ]
}]

const groupsNavTree = [{
    key: 'groups',
    path: `${APP_PREFIX_PATH}/groups`,
    title: 'Groups',
    icon: GroupOutlined,
    breadcrumb: false,
    submenu: [
        {
            key: 'groups-users',
            path: `${APP_PREFIX_PATH}/groups/users`,
            title: 'Groups',
            icon: GroupOutlined,
            breadcrumb: false,
            submenu: []
        },
        {
            key: 'groups-statics',
            path: `${APP_PREFIX_PATH}/groups/statics`,
            title: 'Groups Analytics',
            icon: PieChartOutlined,
            breadcrumb: false,
            submenu: []
        },
        {
            key: 'groups-assignments',
            path: `${APP_PREFIX_PATH}/assignments/all`,
            title: 'Assignments',
            icon: PieChartOutlined,
            breadcrumb: false,
            submenu: []
        },
    ]
}]
const sitesNavTree = [{
    key: 'sites',
    path: `${APP_PREFIX_PATH}/sites`,
    title: ' ',
    icon: GroupOutlined,
    breadcrumb: false,
    submenu: [
        {
            key: 'sites-newcourses',
            path: `${APP_PREFIX_PATH}/sites/courses`,
            title: 'New Courses',
            icon: GroupOutlined,
            breadcrumb: false,
            submenu: []
        },
        {
            key: 'sites-topusers',
            path: `${APP_PREFIX_PATH}/sites/topusers`,
            title: 'Leaderboard',
            icon: GroupOutlined,
            breadcrumb: false,
            submenu: []
        },
        {
            key: 'sites-comments',
            path: `${APP_PREFIX_PATH}/sites/comments`,
            title: 'Comments',
            icon: GroupOutlined,
            breadcrumb: false,
            submenu: []
        },

    ]
}]
const pagesNavTree = [{
    key: 'sites',
    path: `${APP_PREFIX_PATH}/pages`,
    title: 'sidenav.pages',
    icon: GroupOutlined,
    breadcrumb: false,
    submenu: [
        {
            key: 'sites-newcourses',
            path: `${APP_PREFIX_PATH}/pages/`,
            title: 'sidenav.sites.pages',
            icon: GroupOutlined,
            breadcrumb: false,
            submenu: [
                {
                key: 'pages-faq',
                path: `${APP_PREFIX_PATH}/pages/faq`,
                title: 'sidenav.pages.faq',
                icon: GroupOutlined,
                breadcrumb: false,
                submenu: []
            },]
        },


    ]
}]
const authNavTree = [{
    key: 'auth',
    path: `${APP_PREFIX_PATH}/setting`,
    title: 'sidenav.auth',
    icon: AppstoreOutlined,
    breadcrumb: false,
    submenu: [
        {
            key: 'auth-setting',
            path: `${APP_PREFIX_PATH}/setting`,
            title: 'sidenav.apps.setting',
            icon: SettingOutlined,
            breadcrumb: false,
            submenu: []
        },
        {
            key: 'apps-backlink',
            path: `${APP_PREFIX_PATH}/backlink`,
            title: 'sidenav.apps.backlink',
            icon: MailOutlined,
            breadcrumb: false,
            submenu: []
        },
    ]
}]
const navigationConfig = [
    ...backNavTree,
    ...dashBoardNavTree,
    ...appsNavTree,
    ...usersNavTree,
    //...invitesNavTree,
    ...groupsNavTree,
    ...sitesNavTree,
  //  ...pagesNavTree
]

export default navigationConfig;
