import React, {lazy, Suspense} from "react";
import {Switch, Route, Redirect} from "react-router-dom";
import Loading from 'components/shared-components/Loading';
import {APP_PREFIX_PATH} from 'configs/AppConfig'

export const AppViews = () => {
    return (
        <Suspense fallback={<Loading cover="content"/>}>
            <Switch>

                <Route path={`${APP_PREFIX_PATH}/home`} component={lazy(() => import(`./home`))}/>
                <Route path={`${APP_PREFIX_PATH}/profile`} component={lazy(() => import(`./profile`))}/>
                <Route path={`${APP_PREFIX_PATH}/invites/accepted`} component={lazy(() => import(`./invites/accepted`))}/>
                <Route path={`${APP_PREFIX_PATH}/invites/pending`} component={lazy(() => import(`./invites/pending`))}/>
                <Route path={`${APP_PREFIX_PATH}/groups/users`} component={lazy(() => import(`./groups/users`))}/>
                <Route path={`${APP_PREFIX_PATH}/groups/statics`} component={lazy(() => import(`./groups/statics`))}/>
                <Route path={`${APP_PREFIX_PATH}/assignments/all`} component={lazy(() => import(`./questions`))}/>
                <Route path={`${APP_PREFIX_PATH}/assignments/detail/:id`} component={lazy(() => import(`./questions/detail`))}/>
                <Route path={`${APP_PREFIX_PATH}/sites/courses`} component={lazy(() => import(`./sites/newcourses`))}/>
                <Route path={`${APP_PREFIX_PATH}/sites/topusers`} component={lazy(() => import(`./sites/topusers`))}/>
                <Route path={`${APP_PREFIX_PATH}/sites/comments`} component={lazy(() => import(`./sites/comments`))}/>
                <Route path={`${APP_PREFIX_PATH}/pages/faq`} component={lazy(() => import(`./pages`))}/>
                <Redirect from={`${APP_PREFIX_PATH}/invites`} to={`${APP_PREFIX_PATH}/invites/accepted`}/>
                <Redirect from={`${APP_PREFIX_PATH}`} to={`${APP_PREFIX_PATH}/home`}/>
            </Switch>
        </Suspense>
    )
}

export default React.memo(AppViews);