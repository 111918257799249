import fetch from 'auth/FetchInterceptor'
import * as qs from 'qs'

const JwtAuthService = {}

JwtAuthService.login = function (data) {

	return fetch({
		url: '/auth/login',
		method: 'post',
		headers: {
			'public-request':1,
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
    },
	data:qs.stringify(data)
		
	})
}

JwtAuthService.userdata = function (data) {

	return fetch({
		url: '/user/data',
		method: 'post',


	})
}


export default JwtAuthService